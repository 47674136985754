// form
import { useFormContext, Controller } from 'react-hook-form'
// @mui
import { DesktopDateTimePicker, MobileDateTimePicker, DateTimePickerProps } from '@mui/lab'
import useResponsive from '@/hooks/useResponsive'
import { TextField } from '@mui/material'

// ----------------------------------------------------------------------

interface IProps {
  name: string
  helperText?: string
  defaultValue?: any
}

export default function RHFDateTimePicker({
  name,
  helperText,
  defaultValue,
  ...other
}: IProps &
  Pick<DateTimePickerProps, 'inputFormat' | 'views' | 'minDateTime' | 'maxDateTime' | 'label'>) {
  const { control } = useFormContext()
  const isDesktop = useResponsive('up', 'sm')
  const DateTimePicker = isDesktop ? DesktopDateTimePicker : MobileDateTimePicker

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => (
        <DateTimePicker
          {...field}
          {...other}
          renderInput={(params) => (
            <TextField {...params} error={!!error} helperText={helperText ?? error?.message} />
          )}
        />
      )}
    />
  )
}
