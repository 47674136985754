// form
import { useFormContext, Controller } from 'react-hook-form'
// @mui
import { DesktopDatePicker, MobileDatePicker, DatePickerProps } from '@mui/lab'
import useResponsive from '@/hooks/useResponsive'
import { TextField } from '@mui/material'
import { format, isValid, parse } from 'date-fns'

// ----------------------------------------------------------------------

interface IProps {
  name: string
  helperText?: string
  defaultValue?: any
  rifmFormat?: string
}

export default function RHFDatePicker({
  name,
  helperText,
  defaultValue,
  rifmFormat,
  ...other
}: IProps & Pick<DatePickerProps, 'inputFormat' | 'views' | 'minDate' | 'maxDate' | 'label'>) {
  const { control } = useFormContext()
  const isDesktop = useResponsive('up', 'sm')
  const DatePicker = isDesktop ? DesktopDatePicker : MobileDatePicker

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => (
        <DatePicker
          {...field}
          {...other}
          value={
            field.value &&
            other.inputFormat &&
            rifmFormat &&
            isValid(parse(field.value, other.inputFormat, new Date()))
              ? format(parse(field.value, other.inputFormat, new Date()), rifmFormat)
              : field.value
          }
          onChange={(newValue: Date | null, ...restProps) => {
            if (newValue && isValid(newValue) && other.inputFormat) {
              field.onChange(format(newValue, other.inputFormat), ...restProps)
            } else {
              field.onChange(newValue, ...restProps)
            }
          }}
          renderInput={(params) => (
            <TextField {...params} error={!!error} helperText={helperText ?? error?.message} />
          )}
        />
      )}
    />
  )
}
