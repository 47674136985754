// form
import { useFormContext, Controller } from 'react-hook-form'
// @mui
import { TextField, TextFieldProps, SelectProps } from '@mui/material'

// ----------------------------------------------------------------------

interface IProps {
  name: string
  children: any
}

export default function RHFSelect({
  name,
  multiple,
  children,
  ...other
}: IProps & TextFieldProps & Pick<SelectProps, 'multiple'>) {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={other.defaultValue}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          select
          fullWidth
          SelectProps={{ native: true, multiple }}
          error={!!error}
          helperText={error?.message}
          {...other}
        >
          {children}
        </TextField>
      )}
    />
  )
}
