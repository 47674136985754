// form
import { useFormContext, Controller } from 'react-hook-form'
// @mui
import { Slider, SliderProps, FormLabel } from '@mui/material'
import styled from 'styled-components'

// ----------------------------------------------------------------------

interface Props {
  name: string
  label: string
}

const Section = styled.div`
  width: 50%;
  padding-left: 0.5rem;
  @media (max-width: 768px) {
    padding-right: 0.2rem;
    padding-left: 0.2rem;
    width: 100%;
  }
`

export default function RHFSlider({ name, label, defaultValue, ...other }: Props & SliderProps) {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <Section>
          <FormLabel id={label}>{label}</FormLabel>
          <Slider {...other} {...field} defaultValue={defaultValue} valueLabelDisplay="auto" />
        </Section>
      )}
    />
  )
}
