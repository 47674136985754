// form
import { useFormContext, Controller } from 'react-hook-form'
// @mui
import { TimePicker, TimePickerProps } from '@mui/lab'
import { TextField } from '@mui/material'
import { format, isValid, parse } from 'date-fns'

// ----------------------------------------------------------------------

interface IProps {
  name: string
  helperText?: string
  defaultValue?: any
}

export default function RHFTimePicker({
  name,
  helperText,
  defaultValue,
  ...other
}: IProps & Pick<TimePickerProps, 'inputFormat' | 'views' | 'minTime' | 'maxTime' | 'label'>) {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => (
        <TimePicker
          {...field}
          {...other}
          // ampm={false}
          value={
            field.value &&
            other.inputFormat &&
            isValid(parse(field.value, other.inputFormat, new Date()))
              ? parse(field.value, other.inputFormat, new Date())
              : field.value
          }
          onChange={(newValue: Date | null, ...restProps) => {
            if (newValue && isValid(newValue) && other.inputFormat) {
              field.onChange(format(newValue, other.inputFormat), ...restProps)
            } else {
              field.onChange(newValue, ...restProps)
            }
          }}
          renderInput={(params) => (
            <TextField {...params} error={!!error} helperText={helperText ?? error?.message} />
          )}
        />
      )}
    />
  )
}
